/* eslint-disable jsx-a11y/alt-text */
import PhoneScreen from "../assets/images/imgs/phone-screen.svg";
import Logo from "../assets/images/imgs/logo.svg";
import EyeIcon from "../assets/images/icons/ico_eye.svg";
import PlayStore from "../assets/images/imgs/google-play.svg";
import AppStore from "../assets/images/imgs/app-store.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../redux";
import { useFormik } from "formik";
import { SignupSchema } from "../validationScrema/user";
import { register } from "../redux/action";
import { Store } from "../redux/Actions";
import { useEffect, useState } from "react";
import { setToken } from "../utils/utility";
import Footer from "../components/Footer";

export default function SignUp() {
  const dispatch = useDispatch<AppDispatch>();
  const [showPassword, setShowPassword] = useState(false);
  const isRegisterdBackRedirect: boolean = useSelector(
    (state: Store) => state.userDataReducer.isRegisterdBackRedirect
  );
  const handlePasswordVisiblity = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      username: "",
      user_refrence_code: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      setToken("email", values.email);
      setToken("password", values.password);
      dispatch(register(values));
    },
  });

  useEffect(() => {
    if (isRegisterdBackRedirect) {
      navigate("/otp-verification");
    }
  }, [isRegisterdBackRedirect]);
  const { errors, touched } = formik;
  return (
    <>
      <section
        className="login-section signup-section"
        style={{ flexDirection: "column" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="login-box d-flex align-items-center justify-content-center flex-column">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="login-img d-none d-md-flex">
                    <img src={PhoneScreen} className="img-fluid" />
                  </div>
                  <div className="login-right">
                    <div className="login-form">
                      <a href="#" className="logo-link">
                        <img src={Logo} className="img-fluid" />
                      </a>
                      <form onSubmit={formik.handleSubmit}>
                        <div
                          className={`form-group ${
                            errors.first_name ? "error-msg" : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="first_name"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-control"
                            placeholder="First Name"
                          />
                          {errors.first_name && touched.first_name && (
                            <div className="text-danger mt-2">
                              {errors.first_name}
                            </div>
                          )}
                        </div>
                        <div
                          className={`form-group ${
                            errors.last_name ? "error-msg" : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="last_name"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-control"
                            placeholder="Last Name"
                          />
                          {errors.last_name && touched.last_name && (
                            <div className="text-danger mt-2">
                              {errors.last_name}
                            </div>
                          )}
                        </div>
                        <div
                          className={`form-group ${
                            errors.username ? "error-msg" : ""
                          }`}
                        >
                          <input
                            type="text"
                            name="username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-control"
                            placeholder="Username"
                          />
                          {errors.username && touched.username && (
                            <div className="text-danger mt-2">
                              {errors.username}
                            </div>
                          )}
                        </div>
                        <div
                          className={`form-group ${
                            errors.email ? "error-msg" : ""
                          }`}
                        >
                          <input
                            type="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-control"
                            placeholder="Email address"
                          />
                          {errors.email && touched.email && (
                            <div className="text-danger mt-2">
                              {errors.email}
                            </div>
                          )}
                        </div>
                        <div
                          className={`form-group ${
                            errors.phone ? "error-msg" : ""
                          }`}
                        >
                          <input
                            type="number"
                            name="phone"
                            pattern="[0-9]{10}"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-control"
                            placeholder="Phone Number"
                          />
                          {errors.phone && touched.phone && (
                            <div className="text-danger mt-2">
                              {errors.phone}
                            </div>
                          )}
                        </div>
                        <div
                          className={`form-group ${
                            errors.password ? "error-msg" : ""
                          }`}
                        >
                          <div className="input-group">
                            <input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="form-control border-end-0 pe-0"
                              placeholder="Password"
                              aria-label="Password"
                              aria-describedby="basic-addon2"
                            />

                            <img
                              src={EyeIcon}
                              className="img-fluid"
                              style={{ zIndex: 11 }}
                              onClick={handlePasswordVisiblity}
                            />
                          </div>
                          {errors.password && touched.password && (
                            <div className="text-danger mt-2">
                              {errors.password}
                            </div>
                          )}
                        </div>
                        <div className={`form-group`}>
                          <input
                            type="text"
                            name="user_refrence_code"
                            value={formik.values.user_refrence_code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="form-control"
                            placeholder="Referral Code (optional)"
                          />
                        </div>

                        <button type="submit" className="btn btn-login w-100">
                          Signup
                        </button>
                      </form>
                      <p className="mb-0 signup-link text-center">
                        Already have an account?
                        <Link to={"/login"}> Log In</Link>
                      </p>
                    </div>
                    <div className="app-group text-center">
                      <p className="text-center get-app">Get the app</p>
                      <div className="group-button d-flex align-items-center justify-content-center">
                        <button className="btn btn-app">
                          <a href="https://play.google.com/store/apps/details?id=com.app.songzter">
                            <img src={PlayStore} className="img-fluid" />
                          </a>
                        </button>
                        <button className="btn btn-app ms-2">
                          <a href="https://apps.apple.com/in/app/songzter/id1547589823">
                            <img src={AppStore} className="img-fluid" />
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}
