import { useEffect, useRef, useState } from 'react';
import User1 from '../assets/images/imgs/user1.svg';
import MoreIcon from '../assets/images/icons/ico_more.svg';
import LikeIcon from '../assets/images/icons/ico_heart.svg';
import LikeBlackIcon from '../assets/images/icons/ico_like.png';
import CommentIcon from '../assets/images/icons/ico_comment.svg';
import PlayIcon from '../assets/images/icons/ico_play.svg';
import MusicIcon from '../assets/images/icons/ico_music.svg';
import LockIcon from '../assets/images/icons/ico_locknew.svg';
import { useSelector } from 'react-redux';
import { Store } from '../redux/Actions';
import { dispatch } from '../redux';
// import { userDashboardFeed } from "../redux/action";
import { PAYMENT_TYPE, POST_TYPE } from '../constants/App-constant';
import { S3_BASE_URL } from '../service/aws/config';
import CommentModel from './models/CommentModel';
import ShareModel from './models/ShareModel';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getToken } from '../utils/utility';
import SubsctiptionModel from './models/SubscriptionModel';
import UnlocakContentModel from './models/UnlockContentModel';
// import ReactPlayer from "react-player";
import defaultImg from '../assets/images/bg/bg-img.png';
import { addLike } from '../redux/action/postActions';
import DonateModel from './models/Donate';
import { defaultAriaLiveMessages } from 'react-select/dist/declarations/src/accessibility';
import moment from 'moment';
import UnlockContent from '../pages/UnlockContent';
import Slider from 'react-slick';
import { v4 as uuid } from 'uuid';
import TeaserModel from './models/TeaserModel';
import { givePostVote, translate, userDashboardFeed } from '../redux/action';
import playIconBlue from '../assets/images/icons/play-blue.svg';
import { createImageFromInitials, getRandomColor } from '../utils/createImage';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as VerifyIcon } from '../assets/images/icons/verifyIcon.svg';
import UserSubscriptionModal from './models/UserSubscriptionModal';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
};
export default function PostFeed({
  feedList,
  userFeedListData,
  setPage,
  page,
  hasMore,
  setdeleteFeedDependenncy,
  deleteFeedDependenncy,
  fromPage,
  onClick,
}: any) {
  const unlockRef = useRef();
  const navigate = useNavigate();
  const [showPostComment, setShowPostComment] = useState(false);
  const [showTeaser, setShowTeaser] = useState(false);
  const [showShareModel, setShowShareModel] = useState(false);
  const [showSubscriberModel, setShowSubscriberModel] = useState(false);
  const [showUnlockModel, setShowUnlockModel] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [imagePreviewModal, setImagePreviewModal] = useState(false);
  const [pollPreviewImageUrl, setPollPreviewImageUrl] = useState('');
  const [postId, setPostId] = useState();
  const [userId, setUserId] = useState();
  const [userIsFollowing, setUserIsFollowing] = useState();
  const [isFavourite, setIsFavourite] = useState(false);
  const [donate, setDonate] = useState(false);
  const [donateData, setDonateData] = useState<any>(null);
  const [donatePostId, setDonatePostId] = useState<any>(null);
  const [unlockPost, setUnlockPost] = useState<any>(null);
  const [shareLinkInfo, setShareLinkInfo] = useState<{
    web_url: string;
    title: string;
    text: string;
  }>({ web_url: '', title: '', text: '' });
  // const [feedList, setFeedList] = useState<any>([]);
  // const [page, setPage] = useState(1);
  const currentUser = getToken('userId');
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(userDashboardFeed(page));
  }, [showShareModel]);

  const loading: any = useSelector(
    (state: Store) => state.userDataReducer.loading
  );
  const handleDonate = (data: any) => {
    setDonate(!donate);
    setDonateData(data?.user);
    setDonatePostId(data?.id);
  };
  const handlePostCommentClick = (post: any) => {
    onClick(post);
    setShowPostComment(true);
    setPostId(post?.id);
  };

  const handlePostClick = (post: any) => {
    if (
      (post?.is_lock !== true || post?.user_id === Number(currentUser)) &&
      onClick
    ) {
      onClick(post);
      setShowPostComment(true);
      setPostId(post?.id);
      // setPostLock
    }
    //
    //  else if (post?.video_teaser){
    //   setPostId(post?.id);
    //   setShowPostComment(true);
    // }
  };
  const handleTeaser = (post: any) => {
    if (
      (post?.is_lock !== true ||
        post?.user_id === Number(currentUser) ||
        post?.video_teaser) &&
      onClick
    ) {
      setShowTeaser(true);
      setPostId(post?.id);
    }
  };
  const handlePollImagePreview = (url: any) => {
    setPollPreviewImageUrl(url);
    setImagePreviewModal(true);
  };
  const handleGiveVote = async (
    post_id: any,
    poll_option: any,
    feedData: any
  ) => {
    if (feedData.voted) {
      return false;
    } else {
      await dispatch(givePostVote({ post_id, poll_option }));
    }
  };
  useEffect(() => {
    if (!showPostComment) setPostId(undefined);
  }, [showPostComment]);

  const handleOnchangeGiveVote = (data: any) => {
    if (data.voted) {
      return false;
    }
  };
  return (
    <>
      <div className="post-list-box">
        <InfiniteScroll
          dataLength={feedList?.length ? feedList?.length : ''} //This is important field to render the next data
          next={() => {
            // setPage(page + 1);
            setPage(page + 1);
          }}
          hasMore={
            hasMore
            // userFeedListData && userFeedListData.length > 0 ? true : false
          }
          loader={loading && <h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>{t('home.yah! you have seen it all')}</b>
            </p>
          }
        >
          <ul className="post-lists ps-0 mb-0">
            {userFeedListData &&
              userFeedListData?.length > 0 &&
              userFeedListData?.map((feedData: any) => {
                return (
                  <li className="post-item" key={uuid()}>
                    <div className="box-container">
                      <div className="post-user-detail d-flex align-items-center justify-content-between">
                        <Link to={`/user-profile/${feedData?.user?.id}`}>
                          <div className="post-user d-flex align-items-center">
                            <div className="post-user-logo">
                              <img
                                alt="user"
                                // src={
                                //   feedData?.user?.profile_photo
                                //     ? S3_BASE_URL + feedData?.user?.profile_photo
                                //     : User1
                                // }
                                src={
                                  feedData?.user?.profile_photo
                                    ? S3_BASE_URL +
                                      feedData?.user?.profile_photo
                                    : createImageFromInitials(
                                        500,
                                        feedData?.user?.first_name +
                                          ' ' +
                                          feedData?.user?.last_name,
                                        getRandomColor()
                                      )
                                }
                                className="img-fluid"
                                style={{
                                  height: '100%',
                                  width: '100%',
                                  objectFit: 'cover',
                                  boxSizing: 'content-box',
                                }}
                              />
                            </div>
                            <h5 className="post-user-name">
                              {feedData?.user?.first_name +
                                ' ' +
                                feedData?.user?.last_name}
                              {feedData?.user?.user_verified && (
                                <VerifyIcon
                                  className="mx-1"
                                  height={'13'}
                                  width={'13'}
                                />
                              )}
                              <span>@{feedData?.user?.username}</span>
                            </h5>
                          </div>
                        </Link>
                        <div className="post-more">
                          <button
                            className="btn btn-more"
                            onClick={() => {
                              setUserIsFollowing(feedData?.user?.is_following);
                              setPostId(feedData.id);
                              setIsFavourite(feedData.is_favourite);
                              setUserId(feedData.user_id);
                              setShowShareModel(true);
                              setShareLinkInfo({
                                web_url: feedData?.web_url,
                                title: feedData?.title,
                                text: '',
                              });
                            }}
                          >
                            <img
                              src={MoreIcon}
                              className="img-fluid"
                              width="27px"
                              alt="more"
                            />
                          </button>
                        </div>
                      </div>
                      {feedData?.post_type === POST_TYPE.POST_TYPE_IMAGE && (
                        <>
                          {feedData?.is_lock === true &&
                          feedData?.user_id !== Number(currentUser) ? (
                            feedData?.payment_type !==
                              PAYMENT_TYPE.PAYMENT_TYPE_FREE && (
                              <div
                                className="post-image"
                                onClick={() => handlePostClick(feedData)}
                              >
                                <div className="post-lock-img">
                                  <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100">
                                    <div className="lock_icon">
                                      <img
                                        alt="lock"
                                        src={LockIcon}
                                        className="img-fluid"
                                        style={{ width: '50px' }}
                                      />
                                      {feedData?.payment_type ===
                                      PAYMENT_TYPE.PAYMENT_TYPE_SUBSCRIBER_ONLY ? (
                                        <button
                                          className="btn btn-lock"
                                          onClick={() => {
                                            setUserId(feedData?.user_id);
                                            setOpenSubscriptionModal(true);
                                          }}
                                        >
                                          {t('home.subscribe to unlock')}
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-lock btn-lock_img"
                                          onClick={() => {
                                            setUnlockPost(feedData);
                                            setShowUnlockModel(true);
                                          }}
                                        >
                                          {t('home.unlock')}
                                        </button>
                                      )}
                                    </div>

                                    <img
                                      alt="lock"
                                      src={
                                        feedData?.post_type_link &&
                                        `${
                                          feedData?.post_type_link?.startsWith(
                                            'https:'
                                          )
                                            ? ''
                                            : S3_BASE_URL
                                        }${feedData?.post_type_link}`
                                      }
                                      // src={
                                      //   feedData?.post_type_link
                                      //     ? feedData?.post_type_link
                                      //     : feedData?.post_type_link
                                      // }
                                      className="img-fluid"
                                      style={{ filter: 'blur(15px)' }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          ) : (
                            <div
                              className="post-image"
                              data-role="sas"
                              onClick={() => handlePostClick(feedData)}
                            >
                              {feedData?.post_type_link?.split(',').length >
                              1 ? (
                                <Slider
                                  {...settings}
                                  slidesToShow={1}
                                  autoplay={false}
                                >
                                  {feedData?.post_type_link
                                    ?.split(',')
                                    .map((postImg: any) => (
                                      <img
                                        key={uuid()}
                                        src={`${
                                          postImg?.startsWith('https:')
                                            ? ''
                                            : S3_BASE_URL
                                        }${postImg}`}
                                        // src={
                                        //   postImg
                                        //     ? S3_BASE_URL + postImg
                                        //     : defaultImg
                                        // }
                                        className="img-fluid"
                                        alt="bgimg"
                                      />
                                    ))}
                                </Slider>
                              ) : (
                                <img
                                  src={
                                    feedData?.post_type_link === null
                                      ? defaultImg
                                      : `${
                                          feedData?.post_type_link?.startsWith(
                                            'https:'
                                          )
                                            ? ''
                                            : S3_BASE_URL
                                        }${feedData?.post_type_link}`
                                  }
                                  // src={
                                  //   feedData?.post_type_link
                                  //     ? feedData?.post_type_link
                                  //     : defaultImg
                                  // }
                                  className="img-fluid"
                                  alt="bgimg"
                                />
                              )}
                            </div>
                          )}
                        </>
                      )}
                      {feedData?.post_type === POST_TYPE.POST_TYPE_VIDEO && (
                        <>
                          {feedData?.is_lock === true &&
                          feedData?.user_id !== Number(currentUser) ? (
                            feedData.payment_type !==
                              PAYMENT_TYPE.PAYMENT_TYPE_FREE && (
                              <div
                                className="post-image"
                                onClick={() => handlePostClick(feedData)}
                              >
                                <div className="post-lock">
                                  {feedData?.video_teaser && (
                                    <div
                                      className="right-button"
                                      style={{ zIndex: '1' }}
                                    >
                                      <button
                                        className="btn btn-teaser"
                                        onClick={() => handleTeaser(feedData)}
                                        style={{ borderRadius: '13px' }}
                                      >
                                        <img
                                          src={PlayIcon}
                                          className="img-fluid me-1"
                                          style={{
                                            width: '12px',
                                            height: '14px',
                                          }}
                                          alt="icon"
                                        />{' '}
                                        Teaser
                                      </button>
                                    </div>
                                  )}

                                  <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100">
                                    <div className="lock_icon">
                                      <img
                                        src={LockIcon}
                                        className="img-fluid"
                                        alt="lock"
                                        style={{ width: '50px' }}
                                      />
                                      {feedData.payment_type ===
                                      PAYMENT_TYPE.PAYMENT_TYPE_SUBSCRIBER_ONLY ? (
                                        <button
                                          className="btn btn-lock"
                                          onClick={() => {
                                            setUserId(feedData?.user_id);
                                            setOpenSubscriptionModal(true);
                                          }}
                                        >
                                          {t('home.subscribe to unlock')}
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-lock btn-lock_img"
                                          onClick={() => {
                                            setUnlockPost(feedData);
                                            setShowUnlockModel(true);
                                          }}
                                        >
                                          {t('home.unlock')}
                                        </button>
                                      )}
                                    </div>
                                    <img
                                      alt="lock"
                                      src={
                                        feedData?.post_type_link
                                          ? S3_BASE_URL +
                                            feedData?.post_type_link
                                          : S3_BASE_URL +
                                            feedData?.post_type_link
                                      }
                                      className="img-fluid"
                                      style={{ filter: 'blur(15px)' }}
                                    />
                                  </div>
                                </div>
                                <img
                                  src={S3_BASE_URL + feedData?.thumb_image}
                                  className="img-fluid"
                                  alt="feed"
                                />
                              </div>
                            )
                          ) : (
                            <div
                              className="post-image"
                              // onClick={() => handlePostClick(feedData)}
                            >
                              <div
                                className="post-lock"
                                style={{ backdropFilter: 'none' }}
                              >
                                <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100"></div>
                              </div>
                              {feedData?.post_type_link && (
                                <>
                                  <img
                                    src={S3_BASE_URL + feedData?.thumb_image}
                                    className="img-fluid"
                                    alt="bgimg"
                                  />
                                  <button
                                    className="btn btn-teaser11"
                                    onClick={() => handlePostClick(feedData)}
                                    style={{
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%,-50%)',
                                      background: ' #fff',
                                      height: '44px',
                                      width: '44px',
                                      borderRadius: '50%',
                                    }}
                                  >
                                    <img
                                      src={playIconBlue}
                                      className="img-fluid ml-1"
                                      style={{ width: '12px', height: '14px' }}
                                      alt="icon"
                                    />
                                  </button>
                                </>
                              )}
                            </div>
                          )}
                        </>
                      )}
                      {feedData?.post_type === POST_TYPE.POST_TYPE_AUDIO && (
                        <>
                          {feedData?.is_lock === true &&
                          feedData?.user_id !== Number(currentUser) ? (
                            feedData.payment_type !==
                              PAYMENT_TYPE.PAYMENT_TYPE_FREE && (
                              <div
                                className="post-image"
                                onClick={() => handlePostClick(feedData)}
                              >
                                <div className="post-lock">
                                  <div className="right-button">
                                    <div
                                      className="btn-teaser"
                                      style={{ borderRadius: '13px' }}
                                    >
                                      <img
                                        src={MusicIcon}
                                        className="img-fluid me-1"
                                        style={{
                                          width: '12px',
                                          height: '14px',
                                        }}
                                        alt="icon"
                                      />{' '}
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100">
                                    <div className="lock_icon">
                                      <img
                                        src={LockIcon}
                                        className="img-fluid"
                                        alt="lock"
                                        style={{ width: '50px' }}
                                      />
                                      {feedData.payment_type ===
                                      PAYMENT_TYPE.PAYMENT_TYPE_SUBSCRIBER_ONLY ? (
                                        <button
                                          className="btn btn-lock"
                                          onClick={() => {
                                            setUserId(feedData?.user_id);
                                            setOpenSubscriptionModal(true);
                                          }}
                                        >
                                          {t('home.subscribe to unlock')}
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-lock btn-lock_img"
                                          onClick={() => {
                                            setUnlockPost(feedData);
                                            setShowUnlockModel(true);
                                          }}
                                        >
                                          {t('home.unlock')}
                                        </button>
                                      )}
                                    </div>
                                    <img
                                      alt="lock"
                                      src={
                                        feedData?.post_type_link
                                          ? S3_BASE_URL +
                                            feedData?.post_type_link
                                          : S3_BASE_URL +
                                            feedData?.post_type_link
                                      }
                                      className="img-fluid"
                                      style={{ filter: 'blur(15px)' }}
                                    />

                                    {/* {feedData.payment_type ===
                                      PAYMENT_TYPE.PAYMENT_TYPE_SUBSCRIBER_ONLY ? (
                                        <button
                                          className="btn btn-lock"
                                          onClick={() =>{
                                             setUserId(feedData?.user_id);
                                            setOpenSubscriptionModal(true);
                                          }
                                          }
                                        >
                                         {t("home.subscribe to unlock")}
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-lock btn-lock_img"
                                          onClick={() => {
                                            setUnlockPost(feedData);
                                            setShowUnlockModel(true);
                                          }}
                                        >
                                         {t("home.unlock")}
                                        </button>
                                      )}
                                       */}
                                    {/* <button
                                      className="btn btn-lock"
                                      onClick={() => {
                                        setUnlockPost(feedData);
                                        setShowUnlockModel(true);
                                      }}
                                    >
                                     {t("home.unlock")}
                                    </button> */}
                                  </div>
                                </div>
                                <img
                                  src={S3_BASE_URL + feedData?.thumb_image}
                                  className="img-fluid"
                                  alt="feed"
                                />
                              </div>
                            )
                          ) : (
                            <div
                              className="post-image"
                              style={{ background: '#f5f5f5' }}
                              onClick={() => handlePostClick(feedData)}
                            >
                              {feedData?.post_type_link ? (
                                <div
                                  className="d-flex align-items-center justify-content-center h-100 post_audio_cont"
                                  // style={{
                                  //   backgroundImage: `url(${S3_BASE_URL}${feedData?.thumb_image})`,
                                  // }}
                                >
                                  <button
                                    className="post_audio_btn"
                                    onClick={() => console.log('')}
                                  >
                                    <img
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                      }}
                                      src={`${S3_BASE_URL}${feedData?.thumb_image}`}
                                      alt=""
                                    />
                                  </button>
                                </div>
                              ) : (
                                // <audio
                                //   src={S3_BASE_URL + feedData?.post_type_link}
                                //   autoPlay={false}
                                //   controls={true}
                                // />
                                <img
                                  src={defaultImg}
                                  className="img-fluid"
                                  alt="bgimg"
                                />
                              )}
                            </div>
                          )}
                        </>
                      )}
                      {feedData?.post_type === POST_TYPE.POST_TYPE_TEXT && (
                        <>
                          {feedData?.is_lock === true &&
                          feedData?.user_id !== Number(currentUser) ? (
                            feedData.payment_type !==
                              PAYMENT_TYPE.PAYMENT_TYPE_FREE && (
                              <div
                                className="post-image"
                                onClick={() => handlePostClick(feedData)}
                              >
                                <div className="post-lock">
                                  <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100">
                                    <div className="lock_icon">
                                      <img
                                        src={LockIcon}
                                        alt="lock"
                                        className="img-fluid"
                                        style={{ width: '50px' }}
                                      />
                                      {feedData.payment_type ===
                                      PAYMENT_TYPE.PAYMENT_TYPE_SUBSCRIBER_ONLY ? (
                                        <button
                                          className="btn btn-lock"
                                          onClick={() => {
                                            setUserId(feedData?.user_id);
                                            setOpenSubscriptionModal(true);
                                          }}
                                        >
                                          {t('home.subscribe to unlock')}
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-lock btn-lock_img"
                                          onClick={() => {
                                            setUnlockPost(feedData);
                                            setShowUnlockModel(true);
                                          }}
                                        >
                                          {t('home.unlock')}
                                        </button>
                                      )}
                                    </div>
                                    {/* {feedData.payment_type ===
                                    PAYMENT_TYPE.PAYMENT_TYPE_SUBSCRIBER_ONLY ? (
                                      <button
                                        className="btn btn-lock"
                                        onClick={() =>{
                                           setUserId(feedData?.user_id);
                                            setOpenSubscriptionModal(true);
                                        }
                                        }
                                      >
                                       {t("home.subscribe to unlock")}
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-lock btn-lock_img"
                                        onClick={() => {
                                          setUnlockPost(feedData);
                                          setShowUnlockModel(true);
                                        }}
                                      >
                                       {t("home.unlock")}
                                      </button>
                                    )} */}
                                  </div>
                                </div>
                                <img
                                  src={S3_BASE_URL + feedData?.post_type_link}
                                  className="img-fluid"
                                  alt="icon"
                                />
                              </div>
                            )
                          ) : (
                            <div onClick={() => handlePostClick(feedData)}>
                              <p
                                className="caption-view"
                                style={{ whiteSpace: 'break-spaces' }}
                              >
                                {feedData?.description}
                              </p>
                            </div>
                          )}
                        </>
                      )}
                      {feedData?.post_type === POST_TYPE.POST_TYPE_POLL && (
                        <div style={{ cursor: 'pointer' }}>
                          <p className="poll-post-question ml-2 mt-4">
                            {feedData?.description}
                          </p>
                          {!feedData?.poll_options[0]?.image_url ? (
                            <div>
                              {feedData?.poll_options?.map((option: any) => {
                                console.log(option, 'option');
                                return (
                                  <label
                                    className="poll-post-option-child-div m-2 mb-3"
                                    htmlFor={option.text}
                                    onClick={() =>
                                      handleGiveVote(
                                        feedData.id,
                                        option.id,
                                        feedData
                                      )
                                    }
                                    style={{
                                      background: `${
                                        feedData.voted
                                          ? `linear-gradient(to right,rgba(13, 119, 217, 0.15) ${option?.vote_percentage}%, rgba(0, 0, 0, 0)0%)`
                                          : ' '
                                      }`,
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <label className="container-poll">
                                        <input
                                          type="checkbox"
                                          id={option.text}
                                          checked={option.voted}
                                          name={feedData?.id.toString()}
                                          disabled={feedData.voted}
                                          value={option.true}
                                        />
                                        <span className="checkmark-poll"></span>
                                      </label>
                                      <span className="d-flax post-section-options-label">
                                        <span style={{ fontSize: '16px' }}>
                                          {option.text}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: '12px',
                                            color: '#2196f3',
                                          }}
                                        >
                                          {option.votes}{' '}
                                          {option.votes > 1 ? 'votes' : 'vote'}
                                        </span>
                                      </span>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: '12px',
                                          color: '#2196f3',
                                        }}
                                      >
                                        {Math.round(option.vote_percentage)}%
                                      </span>
                                    </div>
                                  </label>
                                );
                              })}
                            </div>
                          ) : (
                            <div>
                              {feedData?.poll_options?.map((option: any) => {
                                return (
                                  <div
                                    className="poll-post-option-child-div m-2 mb-3"
                                    style={{
                                      background: feedData.voted
                                        ? `linear-gradient(to right,rgba(13, 119, 217, 0.15) ${option?.vote_percentage}%, rgba(0, 0, 0, 0)0%)`
                                        : '',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <label className="container-poll">
                                        <input
                                          type="checkbox"
                                          checked={option.voted}
                                          name={feedData?.id.toString()}
                                          onChange={() =>
                                            handleOnchangeGiveVote(feedData)
                                          }
                                          disabled={feedData.voted}
                                        />
                                        <span
                                          className="checkmark-poll"
                                          onClick={() =>
                                            handleGiveVote(
                                              feedData.id,
                                              option.id,
                                              feedData
                                            )
                                          }
                                        ></span>
                                      </label>
                                      <div className="post-poll-image-feed-main-div">
                                        <img
                                          className="post-poll-image-feed-image"
                                          alt="image"
                                          onClick={() =>
                                            handlePollImagePreview(
                                              option.image_url
                                            )
                                          }
                                          src={S3_BASE_URL + option.image_url}
                                        />
                                      </div>
                                      <span
                                        className="post-section-options-label"
                                        onClick={() =>
                                          handleGiveVote(
                                            feedData.id,
                                            option.id,
                                            feedData
                                          )
                                        }
                                      >
                                        <span>{option.text}</span>
                                        <span
                                          style={{
                                            fontSize: '12px',
                                            color: '#2196f3',
                                          }}
                                        >
                                          {option.votes}{' '}
                                          {option.votes > 1 ? 'votes' : 'vote'}
                                        </span>
                                      </span>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: '12px',
                                          color: '#2196f3',
                                        }}
                                      >
                                        {Math.round(option.vote_percentage)}%
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      )}
                      <div className="post-comments">
                        <div className="post-likes d-flex align-items-start justify-content-between">
                          <div className="like-button d-flex">
                            <div className="d-flex align-items-center flex-column me-4">
                              <button
                                className="btn btn-like"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  dispatch(addLike(feedData.id, fromPage));
                                }}
                              >
                                <img
                                  src={
                                    feedData.is_like ? LikeIcon : LikeBlackIcon
                                  }
                                  className="img-fluid"
                                  alt="bgimg"
                                />
                              </button>
                              <small>{feedData?.post_like_counter}</small>
                            </div>
                            <div className="d-flex align-items-center flex-column">
                              <button
                                className="btn btn-like"
                                onClick={() => {
                                  // event.stopPropagation();
                                  // setShowPostComment(true);
                                  // setPostId(feedData.id);
                                  handlePostCommentClick(feedData);
                                }}
                              >
                                <img
                                  src={CommentIcon}
                                  className="img-fluid"
                                  alt="bgimg"
                                />
                              </button>
                              <small>{feedData?.post_comment_counter}</small>
                            </div>
                          </div>

                          {feedData?.user?.id == currentUser ? (
                            ''
                          ) : (
                            <div className="donate-button">
                              <button
                                className="btn btn-donate"
                                onClick={() => handleDonate(feedData)}
                              >
                                $ {t('home.donate')}
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="comment-description">
                          <p>
                            {feedData?.post_type !== POST_TYPE.POST_TYPE_TEXT &&
                              feedData?.post_type !==
                                POST_TYPE.POST_TYPE_POLL &&
                              feedData?.description}
                            {feedData?.hash_tags
                              ? feedData?.hash_tags
                                  ?.split(',')
                                  .map((elem: any) => {
                                    return (
                                      <span
                                        className="text-blue pl-2"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                          navigate(`/tags/${elem}`)
                                        }
                                      >
                                        #{elem}
                                      </span>
                                    );
                                  })
                              : ''}

                            {/* {feedData?.hash_tags && feedData?.hash_tags?.length > 0 &&
                            feedData?.hash_tags?.map((id: number, data: any) => {
                              <span className="text-blue">#{data}</span>;
                            })} */}
                          </p>
                          <p className="mb-0 post-time">
                            {moment(feedData.created_at).fromNow()}
                            {/* pending */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </InfiniteScroll>
      </div>
      <Modal
        show={imagePreviewModal}
        onHide={() => setImagePreviewModal(false)}
        className=""
        centered
        style={{ height: 'auto !important' }}
      >
        <Modal.Body style={{ padding: '15px !important' }}>
          <img
            className=""
            alt="image"
            src={S3_BASE_URL + pollPreviewImageUrl}
            style={{ borderRadius: '10px', height: '100%', width: '100%' }}
          />
        </Modal.Body>
      </Modal>
      {postId && (
        <>
          <CommentModel
            showPostComment={showPostComment}
            setShowPostComment={setShowPostComment}
            postId={postId}
          ></CommentModel>

          <TeaserModel
            showTeaser={showTeaser}
            setShowTeaser={setShowTeaser}
            postId={postId}
          ></TeaserModel>
        </>
      )}
      {/* {showShareModel && ( */}
      <ShareModel
        currentUser={Number(currentUser)}
        setdeleteFeedDependenncy={setdeleteFeedDependenncy}
        deleteFeedDependenncy={deleteFeedDependenncy}
        userFeedListData={userFeedListData}
        showShareModel={showShareModel}
        setShowPostComment={setShowShareModel}
        postId={postId}
        userId={userId}
        userIsFollowing={userIsFollowing}
        page={page}
        fromPage={fromPage}
        isFavourite={isFavourite}
        shareLinkInfo={shareLinkInfo}
      />
      {/* )} */}
      <SubsctiptionModel
        showPostComment={showSubscriberModel}
        setShowPostComment={setShowSubscriberModel}
      ></SubsctiptionModel>
      <UnlocakContentModel
        showPostComment={showUnlockModel}
        setShowPostComment={setShowUnlockModel}
        post={unlockPost}
      ></UnlocakContentModel>
      <DonateModel
        showDonate={donate}
        setDonate={setDonate}
        data={donateData}
        postId={donatePostId}
      />
      {openSubscriptionModal && (
        <UserSubscriptionModal
          openSubscriptionModal={openSubscriptionModal}
          setOpenSubscriptionModal={setOpenSubscriptionModal}
          userId={userId}
          page={page}
        />
      )}
    </>
  );
}
