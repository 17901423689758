import { t } from 'i18next';
import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  username: Yup.string().required('Username is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .min(5, 'Must Contain greterthen 5 Characters'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    )
    .required('Password is Required'),
});
const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter valid email address.')
    .required('Email is Required'),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    )
    .required('Password is Required'),
  acceptTerms: Yup.boolean().oneOf([true], 'Please accept term and condition.'),
});
const changePasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .min(8, 'Minimum 8 Character')
    .required('Old Password is Required'),
  new_password: Yup.string()
    .required('New Password is Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  confirm_new_password: Yup.string()
    .min(8)
    .required('Confirm Password is Required')
    .oneOf([Yup.ref('new_password')], 'Passwords does not match'),
});

const EditProfileSchema = Yup.object().shape({
  first_name: Yup.string().required(`${t('editprofile.firstName required')}`),
  last_name: Yup.string().required(`${t('editprofile.lastName required')}`),
  // username: Yup.string().required("Username is required"),
  // phone_number: Yup.string().required("Phone number is required"),
  // email: Yup.string().email("Invalid email").required("Email is required"),
  // gender: Yup.string().required("gender is required"),
  // country: Yup.string().required("Country is required"),
  // zipcode: Yup.string().required("zipcode is required"),
  // state: Yup.string().required("State is required"),
  // city: Yup.string().required("City is required"),
  // address: Yup.string().required("address is required"),
  // date_of_birth: Yup.date().required("DOB is required"),
});
const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});
const ResetPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .required('New Password is Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  confirm_new_password: Yup.string()
    .min(8)
    .required('Confirm Password is Required')
    .oneOf([Yup.ref('new_password')], 'Passwords does not match'),
});
const OtpSchema = Yup.object().shape({
  email: Yup.number().required('Required'),
});
// const textPostSchema = Yup.object().shape({
//   post_type: Yup.string()
//     .required("Old Password is required."),
//   descriptiong: Yup.string().required("New Password is required.").matches(
//     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
//     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
//   ),
//   hash_tags: Yup.string()
//     .min(8)
//     .required("Confirm password is required")
//     .oneOf([Yup.ref("new_password")], "Passwords does not match"),
//     tags: Yup.string()
//     .min(8)
//     .required("Confirm password is required")
//     .oneOf([Yup.ref("new_password")], "Passwords does not match"),
// });
const textPostSchema = Yup.object().shape({
  description: Yup.string()
    .required('Caption is required.')
    .max(640, 'Please enter less then 640 word'),
});

const PaidPackageSchema = (values: any) => {
  let errors: any = {};
  let isValid = true;
  if (!values.packageName) {
    errors.packageName = 'Package name required !';
    isValid = false;
  } else if (values.packagePrice < 1) {
    errors.packagePrice = 'Package price should be greater than 0';
    isValid = false;
  }
  return { isValid: isValid, errors: errors };
};

const EditBankingSchema = Yup.object().shape({
  bank_holder_name: Yup.string().required('Account holder name is required'),
  bank_name: Yup.string().required('Bank name is required'),
  bank_account_number: Yup.string().required('Account number is required'),
  bank_country: Yup.string().required('Country is required'),
  bank_state: Yup.string().required('State is required'),
  bank_city: Yup.string().required('City is required'),
  bank_routing: Yup.string()
    .required()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .required('Routing is required'),
  bank_address: Yup.string().required('Address is required'),
  bank_swift_code: Yup.string()
    .required()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .required('Swift code is required'),
});
export {
  SignupSchema,
  LoginSchema,
  ForgotPasswordSchema,
  OtpSchema,
  EditProfileSchema,
  changePasswordSchema,
  textPostSchema,
  PaidPackageSchema,
  EditBankingSchema,
  ResetPasswordSchema,
};
