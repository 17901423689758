import io from "socket.io-client";
import { getToken } from "./utility";

const token: String =getToken("jwtToken") || ''
const serverPath:any = `https://chat.songzter.com/?token=${token}`
const socket = io(serverPath);
socket.on("online", (res) => {
console.log('')
const data = 'test'
  socket.emit("session",(data));
  socket.on("session", (data) => {
   console.log('')
  });
}
);



export default socket;
