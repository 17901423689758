import React, { useEffect, useState } from 'react';
import Img1 from '../assets/images/imgs/img1.svg';
import Img2 from '../assets/images/imgs/img2.svg';
import Img3 from '../assets/images/imgs/img3.svg';
import Img4 from '../assets/images/imgs/img4.svg';
import Img5 from '../assets/images/imgs/img5.svg';
import Img6 from '../assets/images/imgs/img6.svg';

import Img7 from '../assets/images/imgs/img7.svg';
import { useNavigate } from 'react-router-dom';
import { Store } from '../redux/Actions';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux';
import { userDetail, userProfileData, userTrandingFeed } from '../redux/action';

import InfiniteScroll from 'react-infinite-scroll-component';
import PostFeed from '../components/PostFeedExplore';
import { getToken } from '../utils/utility';
import Loader from '../components/Loader';

export default function Explore() {
  const baseS3 = 'https://d1jcayykkuly8t.cloudfront.net/';
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [feedList, setFeedList] = useState<any>([]);
  // const isLoggedIn = getToken("authToken") ? true : false;
  const userTrandingFeedList: any = useSelector(
    (state: Store) => state.userDataReducer.userTrandingFeedList
  );
  const userFeedListData: any = useSelector(
    (state: Store) => state.userDataReducer.userTrandingFeedList
  );
  const loading: any = useSelector(
    (state: Store) => state.userDataReducer.loading
  );

  const dispatch = useDispatch<AppDispatch>();
  const hasMoreFeed: any = useSelector(
    (state: Store) => state.userDataReducer.hasMoreFeed
  );
  useEffect(() => {
    const userId = getToken('userId');
    dispatch(userDetail(Number(userId)));
    dispatch(userProfileData(Number(userId)));
  }, []);
  useEffect(() => {
    dispatch(userTrandingFeed(page));
    if (page == 1) {
      window.scrollTo(0, 0);
    }
  }, [page]);

  useEffect(() => {
    // if(feedList.length<=0){
    // setFeedList(userFeedListData)
    setFeedList((previous: any, newValue: any) => {
      if (previous !== newValue) {
        return [...feedList, ...userFeedListData];
      }
    });

    // }
  }, [userFeedListData]);
  return (
    <div className="box-container explore-grid">
      {page == 1 && loading && <Loader />}
      <PostFeed
        setPage={setPage}
        page={page}
        feedList={userFeedListData}
        userFeedListData={userFeedListData}
        hasMore={hasMoreFeed}
        fromPage={'explore'}
      />
      {/* <div className="trend-grid">
        <InfiniteScroll
          dataLength={userTrandingFeedList && userTrandingFeedList?.length} //This is important field to render the next data
          next={() => {
            // setPage(page + 1);
            setPage(page + 1);
          }}
          hasMore={
            true
            // userFeedListData && userFeedListData.length > 0 ? true : false
          }
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>{t("home.yah! you have seen it all")}</b>
            </p>
          }
        >
          {userTrandingFeedList?.length > 0 &&
            userTrandingFeedList.map((data: any) => {
              return (
                <div className="trend-grid__item">
                  <img
                    alt="thumb"
                    src={data.thumb_image ? baseS3 + data.thumb_image : Img1}
                  />
                </div>
              );
            })}
        </InfiniteScroll>
      </div> */}
    </div>
  );
}
