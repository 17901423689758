import React, { useState, useRef, useEffect } from 'react';
import { Store } from '../redux/Actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux';
import { useSelector } from 'react-redux';
import {
  createTextPost,
  getSearchedUserList,
} from '../redux/action/postActions';

// import { searchFilter } from './Filter';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';
import { Button } from 'react-bootstrap';
import { S3_BASE_URL } from '../service/aws/config';
import { ReactComponent as VerifyIcon } from '../assets/images/icons/verifyIcon.svg';

export const searchFilter = (
  searchValue: any,
  list: any,
  searchBy = 'name'
) => {
  let lowerCaseQuery = searchValue.toLowerCase();
  let filteredList = searchValue
    ? list.filter((x: any) =>
        x[searchBy].toLowerCase().includes(lowerCaseQuery)
      )
    : list;
  return filteredList;
};

// const list = [
//   { id: 1, name: 'Tom', type: 'Cat' },
//   { id: 2, name: 'Zeus', type: 'Labrador' },
//   { id: 3, name: 'Jax', type: 'Malamute' },
//   { id: 4, name: 'Seb', type: 'Developer' },
//   { id: 5, name: 'MacBook', type: 'Notebook' },
// ];

const DropdownItems = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchFileLocation, setSearchFileLocation] = useState<any>('');
  const dropdownRef: any = useRef(null);
  const [tagList, setTagList] = useState<any>([]);
  const { loading, createTextPostSuccess, searchUserList }: any = useSelector(
    (state: Store) => state.postDataReducer
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  // click away listener
  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => document.removeEventListener('mousedown', handleClick, false);
  }, []);
  useEffect(() => {
    if (
      location?.pathname != searchFileLocation &&
      !location?.pathname.includes('user-profile')
    ) {
      setSearchValue('');
    }
    setSearchFileLocation(location?.pathname);
  }, [location]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(getSearchedUserList(searchValue));
      setSearchFileLocation(location?.pathname);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);
  const handleClick = (e: any) => {
    if (
      dropdownRef !== null &&
      dropdownRef?.current &&
      dropdownRef?.current.contains(e.target)
    ) {
      return;
    }
    setVisible(false);
  };
  useEffect(() => {
    let data = [];
    data =
      searchUserList &&
      searchUserList.length > 0 &&
      searchUserList.map((data: any) => {
        return {
          value: data.id,
          label: data.created_by,
          username: data.username,
          profile_photo: data.profile_photo,
        };
      });
    if (data && data.length > 0) {
      setTagList(data);
    }
  }, [searchUserList]);
  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
    if (!visible) {
      setVisible(true);
    }
  };

  const selectItem = (item: any) => {
    setSearchValue(item.name);
    setSelectedItem(item.id);
    setVisible(false);
  };

  const selectChange = (e: any) => {
  };
  return (
    <div className="input-box">
      <input
        // className="input"
        type="text"
        placeholder={`${t('home.search something')}`}
        value={searchValue}
        onChange={handleChange}
        onFocus={() => {
          // if (searchValue) {
          setVisible(true);
          // };
        }}
        className="form-control border-start-0"
      />
      {searchValue && (
        <div ref={dropdownRef} className={`dropdown ${visible ? 'v' : ''}`}>
          {visible && (
            <ul>
              {/* {!searchUserList && (
              <li key="zxc" className="dropdown_item">
                no result
              </li>
            )} */}
              {/* you can remove the searchFilter if you get results from Filtered API like Google search */}
              {searchUserList?.length > 0 && !loading ? (
                searchUserList?.map((x: any) => (
                  <li
                    className="d-flex search-user-list-image"
                    onClick={() => {
                      navigate(`/user-profile/${x.id}`);
                      setVisible(false);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <Avatar
                      className="avtar"
                      round={true}
                      src={S3_BASE_URL + x.profile_photo}
                      alt="profile"
                      size="32"
                      name={x.first_name + ' ' + x.last_name}
                      maxInitials={2}
                    />
                    {/* <span>{x.first_name + ' ' + x.last_name}</span>
                    <span>{x.username}</span> */}
                    <div className="ml-2">
                      <h5 className="searchble-user-name">
                        {x?.first_name + ' ' + x?.last_name}{' '}
                        {x?.user_verified ? (
                          <VerifyIcon height={'11'} width={'11'} />
                        ) : (
                          ''
                        )}
                        {/* {x?.user_verified && (
                                <VerifyIcon className="mx-1" />
                              )} */}
                      </h5>
                      <p className="searchble-user-name-firstname">
                        @{x?.username}
                      </p>
                    </div>
                  </li>
                ))
              ) : loading ? (
                <p className="header-searchbar-no-result-found m-3">
                  {t('home.loading')}
                </p>
              ) : (
                searchValue &&
                !loading && (
                  <p className="header-searchbar-no-result-found m-3">
                    {t('home.no result')}
                  </p>
                )
              )}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownItems;
