import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import User1 from '../assets/images/imgs/follow1.svg';
import { AppDispatch } from '../redux';
import { userTranding, followUser, unfollowUser } from '../redux/action';
import { Store } from '../redux/Actions';
import { Link } from 'react-router-dom';
import { S3_BASE_URL } from '../service/aws/config';
import { createImageFromInitials, getRandomColor } from '../utils/createImage';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VerifyIcon } from '../assets/images/icons/verifyIcon.svg';
import { getToken } from '../utils/utility';
export default function Suggestions() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userTrandingList: Array<any> = useSelector(
    (state: Store) => state?.userDataReducer?.userTrandingList
  );
  const dispatch = useDispatch<AppDispatch>();
  const userId = getToken('userId');
  useEffect(() => {
    dispatch(userTranding());
  }, []);
  return (
    <div className="box-container suggestion-container">
      <h5 className="title text-center">{t('home.suggestions')}</h5>
      <div className="user-follow-list">
        <ul className="user-follow-lists ps-0 mb-0">
          {userTrandingList &&
            userTrandingList?.length > 0 &&
            userTrandingList.map((user: any) => {
              if (Number(userId) !== user?.id) {
                return (
                  <li className="user-follow-item">
                    <div className="user-follow-link d-flex justify-content-between align-items-center">
                      <Link to={`/user-profile/${user.id}`}>
                        {(user?.profile_photo || user?.first_name) && (
                          <div className="user-follow-name d-flex align-items-center">
                            <div className="follow-logo">
                              <img
                                src={
                                  user?.profile_photo
                                    ? S3_BASE_URL + user?.profile_photo
                                    : createImageFromInitials(
                                      500,
                                      user?.first_name,
                                      getRandomColor()
                                    )
                                }
                                className="img-fluid"
                                style={{
                                  height: '39px',
                                  width: '39px',
                                  boxSizing: 'content-box',
                                  borderRadius: '50%',
                                }}
                              />
                            </div>
                            <p className="mb-0">
                              {user?.first_name} {user?.last_name}
                              {user?.user_verified ? (
                                <VerifyIcon
                                  className="mx-1"
                                  height={'14'}
                                  width={'14'}
                                />
                              ) : (
                                ''
                              )}
                            </p>
                          </div>
                        )}
                      </Link>
                      <div className="follow-button">
                        <button
                          className={
                            user.is_following
                              ? 'btn btn-unfollow'
                              : 'btn btn-follow'
                          }
                          onClick={() => {
                            if (user.is_following)
                              dispatch(
                                unfollowUser(user.id, 'user-trending-list')
                              );
                            else
                              dispatch(
                                followUser(user.id, 'user-trending-list')
                              );
                          }}
                        >
                          {user.is_following ? `${t('home.unfollow')}` : `${t('home.follow')}`}
                        </button>
                      </div>
                      {/* : <>null</>
                    <div className='unfollow-button'>
                      <button
                        className='btn btn-unfollow'
                        onClick={() => {
                          dispatch(unfollowUser(user?.id));
                          dispatch(userTranding());
                        }}
                      >
                        Unfollow
                      </button>
                    </div> */}
                    </div>
                  </li>
                );
              }
            })}

          {/* {userTrandingList && userTrandingList.map((user:any)=> */}
          {/* <>
            <li className='user-follow-item'>
              <div className='user-follow-link d-flex justify-content-between align-items-center'>
                <div className='user-follow-name d-flex align-items-center'>
                  <div className='follow-logo'>
                    <img src={User1} className='img-fluid' />
                  </div>
                  <p className='mb-0'>Giant Dunzo</p>
                </div>
                <div className='follow-button'>
                  <button className='btn btn-follow'>Follow</button>
                </div>
              </div>
            </li>
            <li className='user-follow-item'>
              <div className='user-follow-link d-flex justify-content-between align-items-center'>
                <div className='user-follow-name d-flex align-items-center'>
                  <div className='follow-logo'>
                    <img src={User1} className='img-fluid' />
                  </div>
                  <p className='mb-0'>Eda Dolton</p>
                </div>
                <div className='follow-button'>
                  <button className='btn btn-follow'>Follow</button>
                </div>
              </div>
            </li>
          </> */}
          {/* )} */}
        </ul>
      </div>
    </div>
  );
}
