import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Button, Modal } from "react-bootstrap";
import {
  followUser,
  gerFollowingList,
  getFollowerList,
  unfollowUser,
  userDetail,
} from "../../redux/action";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux";
import leftArrow from "../../assets/images/icons/left-arrow.svg";
import { Store, UserData } from "../../redux/Actions";
import { useSelector } from "react-redux";
import { S3_BASE_URL } from "../../service/aws/config";
import Loader from "../Loader";
import { ReactComponent as VerifyIcon } from "../../assets/images/icons/verifyIcon.svg";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/utility";
import { t } from "i18next";

interface Props { }

const Following = ({
  isOpenFollowingModal,
  setIsOpenFollowingModal,
  userId,
}: any) => {
  const [page, setPage] = useState(1);
  const [loading1, setLoading1] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { followingList, loading }: UserData = useSelector(
    (state: Store) => state.userDataReducer
  );
  const { id: loggedInUser }: any = useSelector(
    (state: Store) => state.userDataReducer.userDetail
  );
  const { loading: userDetailLoading }: any = useSelector(
    (state: Store) => state.userDataReducer
  );
  const followinUserData: { userId: String; page: number } = { userId, page };

  useEffect(() => {
    const userIdFromToken = getToken("userId");
    dispatch(userDetail(Number(userIdFromToken)));
    dispatch(gerFollowingList(followinUserData));
  }, []);
  useEffect(() => {
    dispatch(gerFollowingList(followinUserData));
  }, [loading1]);
  // const handleFollowingUser = async (id: any) => {
  //   await dispatch(unfollowUser(id));
  //   await dispatch(gerFollowingList(followinUserData));
  //   setLoading1(!loading1);
  // };
  const handleFollowUser = async (id: any) => {
    await dispatch(followUser(id));
    await dispatch(getFollowerList(followinUserData));
    setLoading1(!loading1);
  };
  const handleUnfollowUser = async (id: any) => {
    await dispatch(unfollowUser(id));
    await dispatch(getFollowerList(followinUserData));
    setLoading1(!loading1);
  };
  return (
    <Modal
      show={isOpenFollowingModal}
      onHide={() => setIsOpenFollowingModal(false)}
      className="view-follower-modal"
      centered
    >
      {/* {loading && <Loader />} */}
      {/* <Modal.Body> */}
      <button
        type="button"
        className="btn-close d-md-none"
        data-bs-dismiss="modal"
      ></button>
      <div className="center-box">
        <div className="box-container notification-container">
          <div className="d-flex following-page-title-div">
            {/* <img
              src={leftArrow}
              alt="leftArrow"
              style={{ marginLeft: "12px" }}
            /> */}
            <div></div>
            <h5 className="text-center following-page-title">{t('sidebar.following')}</h5>
            <button
              style={{ marginRight: "12px" }}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={() => setIsOpenFollowingModal(false)}
            ></button>
          </div>
          <div
            className={
              loading
                ? "follower-following-height-scroll-following-modal"
                : "follower-following-height-scroll"
            }
          >
            {loading ? (
              <div className="spinner"></div>
            ) : (
              <div>
                {followingList?.length > 0
                  ? followingList?.map((elem) => {
                    return (
                      <div className="blacklist-link d-flex align-items-center justify-content-between px-0 m-3">
                        <div
                          className="blacklist-user d-flex align-items-center"
                          onClick={() => {
                            navigate(
                              `/user-profile/${elem.following_details.id}`
                            );
                            setIsOpenFollowingModal(false);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <Avatar
                            className="avtar blacklist-setting-image"
                            round={true}
                            src={
                              S3_BASE_URL +
                              elem?.following_details?.profile_photo
                            }
                            alt="profile"
                            size="30"
                            name={
                              elem.following_details?.first_name +
                              " " +
                              elem.following_details?.last_name
                            }
                            maxInitials={2}
                          // style={{ position: "relative" }}
                          />
                          <div className="col">
                            <p className="text-msg user-comment">
                              {elem.following_details?.first_name +
                                " " +
                                elem.following_details?.last_name}
                              {elem.following_details?.user_verified && (
                                <VerifyIcon
                                  className="mx-1"
                                  height={"14"}
                                  width={"14"}
                                />
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="blacklist-button mb-2">
                          {elem.following_details?.is_following ? (
                            <Button
                              className="btn btn-unblock"
                              onClick={() => {
                                handleUnfollowUser(
                                  elem?.following_details?.id
                                );
                              }}
                              style={{ fontSize: "11px" }}
                            >
                              {t('sidebar.following')}
                            </Button>
                          ) : (
                            <>{loggedInUser !== elem?.following_details?.id && < Button
                              variant="outline-secondary"
                              onClick={() => {
                                handleFollowUser(elem?.following_details?.id);
                              }}
                              style={{ width: "81px", fontSize: "11px" }}
                            >
                              Follow
                            </Button>}</>
                          )}
                        </div>
                      </div>
                    );
                  })
                  : !loading && !userDetailLoading && (
                    <div style={{ textAlign: "center" }}>
                      <h4 style={{ marginTop: "30px" }}>No Result Found !</h4>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* </Modal.Body> */}
    </Modal >
  );
};

export default Following;
