import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Store } from '../../redux/Actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux';
import { givePostVote, userFeed } from '../../redux/action';
import {
  ceateComment,
  clearPostData,
  getCommentByPostId,
  getPostById,
} from '../../redux/action/postActions';
import { S3_BASE_URL } from '../../service/aws/config';
import Profile from '../../assets/images/imgs/user-round.svg';
import { PAYMENT_TYPE, POST_TYPE } from '../../constants/App-constant';
import Send from '../../assets/images/icons/send.svg';
import moment from 'moment';
import AudioPlayer from '../AudioPlayer';
import { v4 as uuid } from 'uuid';
import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { USER_FEEDS } from '../../redux/constants/action-types';
import { ReactComponent as VerifyIcon } from '../../assets/images/icons/verifyIcon.svg';
import playIconBlue from '../../assets/images/icons/play-blue.svg';
import { getToken } from '../../utils/utility';
import MusicIcon from '../assets/images/icons/ico_music.svg';
import LockIcon from '../../assets/images/icons/ico_locknew.svg';
import UnlocakContentModel from './UnlockContentModel';
import { useTranslation } from 'react-i18next';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
};
export default function CommentModel({
  showPostComment,
  setShowPostComment,
  postId,
}: any) {
  const { postData, commentData, isCommentCreated, loading }: any = useSelector(
    (state: Store) => state.postDataReducer
  );
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const [showUnlockModel, setShowUnlockModel] = useState(false);
  const [unlockPost, setUnlockPost] = useState<any>(null);
  const [comment, setComment] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoStartPlaying, setVideoStartPlaying] = useState(false);
  const [postDataRevoteError, setPostDataRevoteError] = useState(false);
  const currentUser = getToken('userId');

  useEffect(() => {
    if (showPostComment) {
      dispatch(getPostById(postId));
      dispatch(getCommentByPostId(postId));
    } else {
      dispatch(clearPostData());
    }
  }, [showPostComment]);
  useEffect(() => {}, [postData]);
  useEffect(() => {
    setComment('');
    dispatch(getCommentByPostId(postId));
  }, [isCommentCreated]);

  const handleChildReplayRedirect = (id: any) => {
    if (location.pathname.includes('/user-profile')) {
      setShowPostComment(false);
      dispatch({ type: USER_FEEDS.USER_FEEDS_INIT_CLEAR });
      dispatch(userFeed(1, id));
      navigate(`/user-profile/${id}`);
    } else {
      navigate(`/user-profile/${id}`);
    }
  };
  const handleReplayRedirect = (id: any) => {
    if (location.pathname.includes('/user-profile')) {
      setShowPostComment(false);
      dispatch({ type: USER_FEEDS.USER_FEEDS_INIT_CLEAR });
      dispatch(userFeed(1, id));
      navigate(`/user-profile/${id}`);
    } else {
      navigate(`/user-profile/${id}`);
    }
  };
  const handlePressEnterValue = (e: any) => {
    if (e?.keyCode === 13) {
      dispatch(
        ceateComment(
          commentId
            ? {
                data: {
                  comment: comment,
                  post_id: postId,
                  parent_id: commentId?.id,
                },
                onSuccess: () => setCommentId({}),
              }
            : {
                data: { comment: comment, post_id: postId },
                onSuccess: () => setCommentId({}),
              }
        )
      );
    }
  };
  const handleGiveVote = async (post_id: any, poll_option: any) => {
    setPostDataRevoteError(false);
    if (!postData?.voted) {
      await dispatch(givePostVote({ post_id, poll_option }));
    } else {
      setPostDataRevoteError(true);
    }
  };
  const [commentId, setCommentId] = useState<any>({});
  const handleGiveVoteChange = () => {
    if (!postData?.voted) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Modal
      show={showPostComment}
      onHide={() => {
        setShowPostComment(false);
        setVideoStartPlaying(false);
        setIsPlaying(false);
        setPostDataRevoteError(false);
      }}
      centered
      className="custom-modal comment-modal"
    >
      <Modal.Body>
        {postData?.user?.first_name ? (
          <div className="main-comment-box">
            <div
              className={`left-section d-flex cmt-img-wrapper ${
                ((postData?.post_type === POST_TYPE.POST_TYPE_VIDEO &&
                  postData?.is_lock === true &&
                  postData.payment_type !== 0) ||
                  (postData?.post_type === POST_TYPE.POST_TYPE_AUDIO &&
                    postData?.is_lock === true)) &&
                postData?.user_id !== Number(currentUser)
                  ? 'comment-modal-video-preview-main'
                  : ''
              }
            ${
              postData?.post_type === POST_TYPE.POST_TYPE_VIDEO &&
              !videoStartPlaying &&
              'video-preview-and-thumbnail-button'
            }${
                postData?.post_type === POST_TYPE.POST_TYPE_VIDEO &&
                postData?.is_lock === true &&
                postData.payment_type !== 0
                  ? 'video-image-size-validation'
                  : ''
              } ${
                postData?.post_type === POST_TYPE.POST_TYPE_POLL &&
                'poll-image-backgeround'
              }`}
              style={{
                justifyContent: 'center',
                alignItems: `${
                  postData?.post_type !== POST_TYPE.POST_TYPE_POLL
                    ? 'center'
                    : ''
                }`,
              }}
            >
              {
                postData?.post_type === POST_TYPE.POST_TYPE_VIDEO &&
                  // <div className="post-lock-img">
                  (postData?.is_lock === true &&
                  postData?.user_id !== Number(currentUser) ? (
                    postData.payment_type !==
                      PAYMENT_TYPE.PAYMENT_TYPE_FREE && (
                      <div
                        className="post-image"
                        // onClick={() => handlePostClick(postData)}
                      >
                        <div className="post-lock">
                          <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100">
                            <div className="lock_icon-comment">
                              <img
                                src={LockIcon}
                                className="img-fluid comment-modal-image-lock-video"
                                alt="lock"
                                style={{ width: '50px' }}
                              />
                              {postData.payment_type ===
                              PAYMENT_TYPE.PAYMENT_TYPE_SUBSCRIBER_ONLY ? (
                                <button
                                  className="btn btn-lock"
                                  // onClick={() => {
                                  //   setUserId(postData?.user_id);
                                  //   setOpenSubscriptionModal(true);
                                  // }}
                                >
                                  {t('home.subscribe to unlock')}
                                </button>
                              ) : (
                                <button
                                  className="btn btn-lock btn-lock_img"
                                  onClick={() => {
                                    setUnlockPost(postData);
                                    setShowUnlockModel(true);
                                  }}
                                >
                                  {t('home.unlock')}
                                </button>
                              )}
                            </div>
                            <video
                              // alt="lock"
                              src={
                                postData?.post_type_link
                                  ? S3_BASE_URL + postData?.post_type_link
                                  : S3_BASE_URL + postData?.post_type_link
                              }
                              className="img-fluid"
                              style={{ filter: 'blur(15px)' }}
                            />
                          </div>
                        </div>
                        {/* <img
                      src={S3_BASE_URL + postData?.thumb_image}
                      className="img-fluid"
                      alt="feed"
                    /> */}
                      </div>
                    )
                  ) : (
                    <>
                      {videoStartPlaying ? (
                        <video
                          src={S3_BASE_URL + postData?.post_type_link}
                          width="100%"
                          height="100%"
                          autoPlay={true}
                          controls={videoStartPlaying}
                        />
                      ) : (
                        <>
                          <img
                            src={S3_BASE_URL + postData?.thumb_image}
                            className="img-fluid"
                            style={{ maxHeight: '600px' }}
                            alt="feed"
                          />
                          <button
                            className="btn btn-teaser11"
                            onClick={() => setVideoStartPlaying(true)}
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: postData?.is_lock === true ? '29%' : '50%',
                              transform: 'translate(-50%,-50%)',
                              background: ' #fff',
                              height: '55px',
                              width: '55px',
                              borderRadius: '50%',
                            }}
                          >
                            <img
                              src={playIconBlue}
                              className="img-fluid ml-1"
                              style={{ width: '25px', height: '25px' }}
                              alt="icon"
                            />
                          </button>
                        </>
                      )}
                    </>
                  ))
                // </div>
              }
              {postData?.post_type === POST_TYPE.POST_TYPE_IMAGE &&
                (postData?.is_lock === true &&
                postData?.user_id !== Number(currentUser) ? (
                  postData?.payment_type !== PAYMENT_TYPE.PAYMENT_TYPE_FREE && (
                    <div className="post-image">
                      <div className="post-lock-img">
                        <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100">
                          <div className="lock_icon-comment">
                            <img
                              alt="lock"
                              src={LockIcon}
                              className="img-fluid comment-modal-image-lock"
                              style={{ width: '50px' }}
                            />
                            {postData?.payment_type ===
                            PAYMENT_TYPE.PAYMENT_TYPE_SUBSCRIBER_ONLY ? (
                              <button
                                className="btn btn-lock"
                                // onClick={() => {
                                //   setUserId(postData?.user_id);
                                //   setOpenSubscriptionModal(true);
                                // }}
                              >
                                {t('home.subscribe to unlock')}
                              </button>
                            ) : (
                              <button
                                className="btn btn-lock btn-lock_img"
                                onClick={() => {
                                  setUnlockPost(postData);
                                  setShowUnlockModel(true);
                                }}
                              >
                                {t('home.unlock')}
                              </button>
                            )}
                          </div>
                          <img
                            src={`${
                              postData?.post_type_link?.startsWith('https:')
                                ? ''
                                : S3_BASE_URL
                            }${postData?.post_type_link}`}
                            className="img-fluid preview"
                            alt="lock"
                            style={{ filter: 'blur(15px)' }}
                          />
                          {/* <img
                            src={postData?.post_type_link}
                            className="img-fluid preview"
                            alt="lock"
                          /> */}

                          {/* <img
                            alt="lock"
                            src={
                              postData?.post_type_link &&
                              `${
                                postData?.post_type_link?.startsWith('https:')
                                  ? ''
                                  : S3_BASE_URL
                              }${postData?.post_type_link}`
                            }
                            // src={
                            //   postData?.post_type_link
                            //     ? postData?.post_type_link
                            //     : postData?.post_type_link
                            // }
                            className="img-fluid"
                            style={{ filter: 'blur(15px)' }}
                          /> */}
                        </div>
                      </div>
                    </div>
                  )
                ) : postData?.post_type_link?.split(',').length > 1 ? (
                  // <div className="post-image-comment">
                  <AwesomeSlider
                    animation="cubeAnimation"
                    className="awasome-slidere-main-div"
                    bullets={false}
                  >
                    {postData?.post_type_link
                      ?.split(',')
                      .map((postImg: any) => (
                        <div>
                          <img
                            key={uuid()}
                            src={`${
                              postImg?.startsWith('https:') ? '' : S3_BASE_URL
                            }${postImg}`}
                            // src={S3_BASE_URL + postImg}
                            className="img-fluid multiple-image-slider-height"
                            alt="bgimg"
                          />
                        </div>
                      ))}
                  </AwesomeSlider>
                ) : (
                  // </div>
                  <img
                    src={`${
                      postData?.post_type_link?.startsWith('https:')
                        ? ''
                        : S3_BASE_URL
                    }${postData?.post_type_link}`}
                    className="img-fluid preview"
                    alt="bgimg"
                  />
                  // <img
                  //   src={postData?.post_type_link}
                  //   className="img-fluid preview"
                  //   alt="bgimg"
                  // />
                ))}
              {/* {postData?.post_type === POST_TYPE.POST_TYPE_IMAGE &&
              (postData?.post_type_link?.split(',').length > 1 ? (
                <div className="post-image-comment">
                  <Slider {...settings} slidesToShow={1} autoplay={false}>
                    {postData?.post_type_link
                      ?.split(',')
                      .map((postImg: any) => (
                        <img
                          key={uuid()}
                          src={`${
                            postImg?.startsWith('https:') ? '' : S3_BASE_URL
                          }${postImg}`}
                          // src={S3_BASE_URL + postImg}
                          className="img-fluid"
                          alt="bgimg"
                        />
                      ))}
                  </Slider>
                </div>
              ) : (
                <img
                  src={`${
                    postData?.post_type_link?.startsWith('https:')
                      ? ''
                      : S3_BASE_URL
                  }${postData?.post_type_link}`}
                  className="img-fluid preview"
                  alt="bgimg"
                />
                // <img
                //   src={postData?.post_type_link}
                //   className="img-fluid preview"
                //   alt="bgimg"
                // />
              ))} */}

              {postData?.post_type === POST_TYPE.POST_TYPE_AUDIO &&
                (postData?.is_lock === true &&
                postData?.user_id !== Number(currentUser) ? (
                  postData.payment_type !== PAYMENT_TYPE.PAYMENT_TYPE_FREE && (
                    <div className="post-lock-img">
                      <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100">
                        <div className="lock_icon-comment">
                          <img
                            src={LockIcon}
                            className="img-fluid comment-modal-image-lock"
                            alt="lock"
                            style={{ width: '50px' }}
                          />
                          {postData.payment_type ===
                          PAYMENT_TYPE.PAYMENT_TYPE_SUBSCRIBER_ONLY ? (
                            <button
                              className="btn btn-lock"
                              // onClick={() => {
                              //   setUserId(postData?.user_id);
                              //   setOpenSubscriptionModal(true);
                              // }}
                            >
                              {t('home.subscribe to unlock')}
                            </button>
                          ) : (
                            <button
                              className="btn btn-lock btn-lock_img"
                              onClick={() => {
                                setUnlockPost(postData);
                                setShowUnlockModel(true);
                              }}
                            >
                              {t('home.unlock')}
                            </button>
                          )}
                        </div>
                        {/* <div className="audio-post-modal-container">
                          <div className="audio-thumb">
                            <img
                              src={S3_BASE_URL + postData?.thumb_image}
                              className="img-fluid"
                              alt="bgimg"
                              style={{ objectFit: 'cover' }}
                            />
                          </div>
                          <div className="audio-sound">
                            <div className="sound-control">
                              <AudioPlayer
                                track={S3_BASE_URL + postData?.post_type_link}
                                isPlaying={isPlaying}
                                setIsPlaying={setIsPlaying}
                              />
                            </div>
                          </div>
                        </div> */}
                        <img
                          alt="lock"
                          src={
                            postData?.thumb_image
                              ? S3_BASE_URL + postData?.thumb_image
                              : S3_BASE_URL + postData?.thumb_image
                          }
                          className="img-fluid"
                          style={{ filter: 'blur(15px)' }}
                        />
                      </div>
                      {/* <div className="audio-post-modal-container">
                      <div className="audio-thumb">
                        <img
                          src={S3_BASE_URL + postData?.thumb_image}
                          className="img-fluid"
                          alt="bgimg"
                          style={{ objectFit: 'cover' }}
                        />
                      </div>
                      <div className="audio-sound">
                        <div className="sound-control">
                          <AudioPlayer
                            track={S3_BASE_URL + postData?.post_type_link}
                            isPlaying={isPlaying}
                            setIsPlaying={setIsPlaying}
                          />
                        </div>
                      </div>
                    </div> */}
                    </div>
                  )
                ) : (
                  <div className="audio-post-modal-container">
                    <div className="audio-thumb" style={{ width: '560px' }}>
                      <img
                        src={S3_BASE_URL + postData?.thumb_image}
                        className="img-fluid"
                        alt="bgimg"
                        style={{ objectFit: 'cover' }}
                      />
                    </div>
                    <div className="audio-sound">
                      <div className="sound-control">
                        <AudioPlayer
                          track={S3_BASE_URL + postData?.post_type_link}
                          isPlaying={isPlaying}
                          setIsPlaying={setIsPlaying}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              {postData?.post_type === POST_TYPE.POST_TYPE_TEXT && (
                <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                  <p className="ps-5 pe-5 ms-4 me-4 comment-text-modal-white-space">
                    {postData?.description}
                  </p>
                </div>
              )}
              {postData?.post_type === POST_TYPE.POST_TYPE_POLL && (
                <div>
                  <p className="poll-post-question ml-2 mt-4">
                    {postData?.description}
                  </p>
                  {!postData?.poll_options[0]?.image_url ? (
                    <div>
                      {postData?.poll_options?.map((option: any) => {
                        return (
                          <label
                            className="poll-post-option-child-div m-2 mb-3"
                            htmlFor={option.text}
                            onClick={() =>
                              handleGiveVote(postData.id, option.id)
                            }
                            style={{
                              background: `${
                                postData.voted
                                  ? `linear-gradient(to right,rgba(13, 119, 217, 0.15) ${option?.vote_percentage}%, rgba(0, 0, 0, 0)0%)`
                                  : ' '
                              }`,
                            }}
                          >
                            <label className="container-poll">
                              <input
                                type="checkbox"
                                id={option.text}
                                checked={option.voted}
                                name={postData?.id.toString()}
                                disabled={postData.voted}
                                value={option.true}
                              />
                              <span className="checkmark-poll"></span>
                            </label>
                            <span className="post-section-options-label ">
                              {option.text}
                            </span>
                          </label>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      {postData?.poll_options?.map((option: any) => {
                        return (
                          <label
                            className="poll-post-option-child-div m-2 mb-3"
                            htmlFor={option.poll_id}
                            onClick={() =>
                              handleGiveVote(postData.id, option.id)
                            }
                            style={{
                              background: `linear-gradient(to right,rgba(13, 119, 217, 0.15) ${option?.vote_percentage}%, rgba(0, 0, 0, 0)0%)`,
                            }}
                          >
                            <label className="container-poll">
                              <input
                                type="checkbox"
                                checked={option.voted}
                                name={postData?.id.toString()}
                                // onChange={() =>
                                //   handleOnchangeGiveVote(postData)
                                // }
                                disabled={postData.voted}
                              />
                              <span
                                className="checkmark-poll"
                                onClick={() =>
                                  handleGiveVote(postData.id, option.id)
                                }
                              ></span>
                            </label>
                            {/* <input
                              className="option-radio-button"
                              type="radio"
                              id={option.poll_id}
                              name={postData?.description}
                              value={option.poll_id}
                              defaultChecked={option.voted}
                            /> */}
                            <div className="post-poll-image-feed-main-div">
                              <img
                                className="post-poll-image-feed-image"
                                alt="image"
                                // style={{borderRadius:"6px !important"}}
                                src={S3_BASE_URL + option.image_url}
                              />
                            </div>
                            <span className="post-section-options-label">
                              {option.text}
                            </span>
                          </label>
                        );
                      })}
                    </div>
                  )}
                  <p className="poll-quesion-error mt-2 ml-2">
                    {postDataRevoteError && 'You have already voted this poll'}
                  </p>
                </div>
              )}
            </div>
            <div
              className={`right-section ${
                postData?.post_type === POST_TYPE.POST_TYPE_POLL &&
                'poll-comment-backgeround'
              }`}
            >
              <div className="modal-header">
                <div className="post-user  p-0 post-user-control d-flex align-items-center">
                  <div className="post-user-logo">
                    <Avatar
                      style={{ objectFit: 'cover' }}
                      className="avtar"
                      round={true}
                      src={S3_BASE_URL + postData?.user?.profile_photo}
                      alt="profile"
                      size="54"
                      name={
                        postData?.user?.first_name +
                        ' ' +
                        postData?.user?.last_name
                      }
                      maxInitials={2}
                    />
                  </div>
                  <div
                    className="col"
                    onClick={() =>
                      navigate(`/user-profile/${postData?.user?.id}`)
                    }
                  >
                    <h5 className="post-user-name">
                      {postData?.created_by}{' '}
                      {postData?.user?.user_verified ? (
                        <VerifyIcon
                          className="mx-1"
                          height={'13'}
                          width={'13'}
                        />
                      ) : (
                        ''
                      )}
                    </h5>
                    <p className="text-msg"> @{postData?.user?.username} </p>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={() => setShowPostComment(false)}
                ></button>
              </div>
              <div className="modal-body">
                {commentData &&
                  commentData.length > 0 &&
                  commentData.map((comment: any) => {
                    return (
                      <div className="post-user post-user-control d-flex align-items-start px-0">
                        <div className="post-user-logo post-user-logo-sm">
                          <Avatar
                            className="avtar"
                            round={true}
                            src={
                              S3_BASE_URL + comment?.user_details?.profile_photo
                            }
                            alt="profile"
                            size="30"
                            name={
                              comment?.user_details?.first_name.replace(
                                ' ',
                                ''
                              ) +
                              ' ' +
                              comment?.user_details?.last_name
                            }
                            maxInitials={2}
                          />
                        </div>
                        <div className="col">
                          <h5
                            className="post-user-name justify-content-start"
                            onClick={() =>
                              handleReplayRedirect(comment.user_id)
                            }
                          >
                            {comment?.user_details?.first_name +
                              ' ' +
                              comment?.user_details?.last_name}{' '}
                            {comment?.user_details?.user_verified ? (
                              <VerifyIcon
                                className="mx-1"
                                height={'13'}
                                width={'13'}
                              />
                            ) : (
                              ''
                            )}
                            <p className="text-time m-0 ps-3">
                              {moment(comment?.created_at).fromNow()}
                              {/* pending */}
                            </p>
                          </h5>
                          <p className="text-msg user-comment">
                            {comment?.comment}
                          </p>
                          <div className="d-flex comment-control">
                            {/* <button className="btn btn-link btn-link-danger">
                            Report
                          </button> */}
                            <button
                              className="btn btn-link"
                              onClick={() => setCommentId(comment)}
                            >
                              {t('home.reply')}
                            </button>
                          </div>
                          {comment?.replies &&
                            comment?.replies.length > 0 &&
                            comment?.replies.map((reply: any) => {
                              return (
                                <div className="post-user post-user-control d-flex align-items-start px-0">
                                  <div className="post-user-logo post-user-logo-sm">
                                    {/* <img
                                    src={
                                      reply?.user_details?.profile_photo
                                        ? S3_BASE_URL +
                                        reply?.user_details?.profile_photo
                                        : Profile
                                    }
                                    className="img-fluid"
                                    alt=""
                                  /> */}
                                    <Avatar
                                      className="avtar"
                                      round={true}
                                      src={
                                        S3_BASE_URL +
                                        reply?.user_details?.profile_photo
                                      }
                                      alt="profile"
                                      size="30"
                                      name={
                                        reply?.user_details?.first_name.replace(
                                          ' ',
                                          ''
                                        ) +
                                        ' ' +
                                        reply?.user_details?.last_name
                                      }
                                      maxInitials={2}
                                    />
                                  </div>
                                  <div className="col">
                                    <h5
                                      className="post-user-name justify-content-start"
                                      onClick={() =>
                                        handleChildReplayRedirect(
                                          reply?.user_id
                                        )
                                      }
                                    >
                                      {reply?.user_details?.first_name +
                                        ' ' +
                                        reply?.user_details?.last_name}
                                      {reply?.user_details?.user_verified ? (
                                        <VerifyIcon
                                          className="mx-1"
                                          height={'13'}
                                          width={'13'}
                                        />
                                      ) : (
                                        ''
                                      )}
                                      <p className="text-time m-0 ps-3">
                                        {' '}
                                        {moment(reply?.created_at).fromNow()}
                                      </p>
                                    </h5>
                                    <p className="text-msg user-comment">
                                      {reply?.comment}
                                    </p>
                                    <div className="d-flex comment-control">
                                      {/* <button className="btn btn-link btn-link-danger">
                                      Report
                                    </button> */}
                                      <button
                                        className="btn btn-link"
                                        onClick={() => setCommentId(comment)}
                                      >
                                        Reply
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
              </div>
              {commentId?.id && (
                <div className="reply-to-text">
                  Reply to: @{commentId?.created_by}{' '}
                </div>
              )}
              <div className="modal-footer flex-nowrap position-relative">
                <input
                  type="text"
                  className="form-control border-0"
                  placeholder={`${t('home.write comment')}`}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  onKeyDown={handlePressEnterValue}
                />
                <button
                  type="button"
                  className="btn m-0"
                  onClick={() => {
                    dispatch(
                      ceateComment(
                        commentId
                          ? {
                              data: {
                                comment: comment,
                                post_id: postId,
                                parent_id: commentId?.id,
                              },
                              onSuccess: () => setCommentId({}),
                            }
                          : {
                              data: { comment: comment, post_id: postId },
                              onSuccess: () => setCommentId({}),
                            }
                      )
                    );
                  }}
                  // disabled={loading ? true : false}
                >
                  <img src={Send} alt="" />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="comment-modal-loader">
            <div className="spinner"></div>
          </div>
        )}

        <UnlocakContentModel
          showPostComment={showUnlockModel}
          setShowPostComment={setShowUnlockModel}
          post={unlockPost}
        ></UnlocakContentModel>
      </Modal.Body>
    </Modal>
  );
}
