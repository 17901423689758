import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { AppDispatch } from '../redux';
import {
  otpVerification,
  otpVerificationUser,
  resendOtp,
} from '../redux/action';
import { Store, UserData } from '../redux/Actions';
import { getToken } from '../utils/utility';
import { OtpSchema } from '../validationScrema/user';
import { FORGOT_PASSWORD, REGISTER } from '../redux/constants/action-types';

export default function OtpVerification() {
  const [otp, setOtp] = useState();
  const dispatch = useDispatch<AppDispatch>();
  const email = getToken('email');
  const navigate = useNavigate();
  const {
    isOtpVerifiedSuccess,
    isForgotpasswordSuccess,
    isOtpUserVerifiedSuccess,
    isRegisterd,
  }: UserData = useSelector((state: Store) => state.userDataReducer);
  const formik = useFormik({
    initialValues: {
      otp: otp,
    },
    // validationSchema: OtpSchema,
    onSubmit: (values) => {

      if (isRegisterd && isForgotpasswordSuccess === false) {
        dispatch(otpVerificationUser({ otp: values.otp, email: email }));
      } else {
        dispatch(otpVerification({ otp: values.otp, email: email }));
      }
    },
  });

  const { errors, touched, setFieldValue } = formik;
  useEffect(() => {
    dispatch({
      type: FORGOT_PASSWORD.FORGOT_PASSWORD_BACK_ACTION,
      payload: false,
    });
    dispatch({
      type: REGISTER.REGISTER_ERORR_BACK_ACTION,
      payload: false,
    });
  }, []);
  const localStorageData: any = localStorage.getItem('otpSucessBack');
  const resetPassState = localStorageData
    ? JSON.parse(localStorageData)
    : localStorageData;
  useEffect(() => {
    setFieldValue('otp', otp);
  }, [otp]);
  useEffect(() => {
    if ((isForgotpasswordSuccess && isOtpVerifiedSuccess) || resetPassState) {
      localStorage.setItem('otpSucessBack', JSON.stringify(true));
      navigate('/reset-password');
    } else if (isOtpUserVerifiedSuccess) {
      navigate('/home');
    }
  }, [isOtpVerifiedSuccess, isOtpUserVerifiedSuccess]);
  return (
    <section className="login-section forgot-psw otp-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="login-box d-flex align-items-center justify-content-center flex-column">
              <div className="d-flex align-items-center justify-content-center">
                <div className="login-right ms-0">
                  <div className="login-form">
                    <h2 className="login-title">Verify OTP</h2>
                    <form>
                      <div className="form-group d-flex align-items-center justify-content-center">
                        {/* <input className="otp form-control" type="text"/>
                                            <input className="otp form-control" type="text"/>
                                            <input className="otp form-control" type="text"/>
                                            <input className="otp form-control" type="text"/>
                                            <input className="otp form-control" type="text"/> */}
                        <OtpInput
                          //  className="otp form-control"
                          value={formik.values.otp}
                          onChange={(otp: any) => setOtp(otp)}
                          numInputs={6}
                          separator={<span> - </span>}
                        />
                        {errors.otp && touched.otp && (
                          <div className="text-danger mt-2">{errors.otp}</div>
                        )}
                      </div>
                      <button
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          formik.handleSubmit();
                        }}
                        className="btn btn-login w-100"
                      >
                        Verify
                      </button>
                    </form>
                    <p className="mb-0 signup-link text-center">
                      Your otp send in {email}.
                    </p>
                    <div
                      className="text-center"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <p className="mb-0 signup-link text-center">
                        Didn’t get OTP yet?
                      </p>
                      <a
                        href="#"
                        className="forgot-link text-center"
                        onClick={() => dispatch(resendOtp({ email: email }))}
                      >
                        Send again
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="footer d-flex align-items-center">
                <div className="footer-select">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>English</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <p className="mb-0 ms-sm-1">
                  © 2022 Songzter from Cybertek Networks
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}
