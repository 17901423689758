import React, { useEffect, useState } from "react";
import user from "../assets/images/imgs/user2.svg";
import dollor from "../assets/images/imgs/dollor.svg";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Store, PayoutData } from "../redux/Actions";
import { AddPayout, payoutHistory, wallet } from "../redux/action";
import { dispatch } from "../redux";
import moment from "moment";
import PayoutModal from "./models/PayoutModal";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import { toast } from "react-toastify";

const Payout = () => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState<any>("");
  const [amountError, setAmountError] = useState<any>("");
  const [page, setPage] = useState(1);
  const [packageId, setPackageId] = useState<any>("");

  const payoutList: any = useSelector(
    (state: Store) => state?.payoutReducer?.payoutHistory
  );
  const { postData } = useSelector(
    (state: Store) => state?.walletReducer
  );
  const loading: any = useSelector(
    (state: Store) => state.payoutReducer?.loading
  );
  const [showPayoutModal, setShowPayoutModel] = useState(false);
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const { isInAppLoading, isInAppSuccess, isPaymentSuccess }: any = useSelector(
    (state: Store) => state.payoutReducer
  );

  // useEffect(() => {
  //   if (isInAppSuccess) {
  //     setShowPayoutModel(false)
  //   }
  // }, [isInAppSuccess]);
  useEffect(() => {
    dispatch(payoutHistory(page));
  }, [isPaymentSuccess]);

  useEffect(() => {
    //@ts-ignore
    dispatch(wallet(page));
  }, [page, isInAppSuccess]);

  const addPayout = () => {
    if (amount > 0) {
      const data: { amount: any } = { amount };
      dispatch(AddPayout(data));
      setAmount("");
      dispatch(payoutHistory(page));
      setAmountError(false);
    } else {
      setAmountError(true);
      toast.error("Please enter amount greater then 0");
    }
  };

  const addPayoutModal = () => {
    setShowPayoutModel(true);
  };

  return (
    <>
      {loading && <Loader />}
      <h2 className="accordion-header d-lg-none" id="headingThree">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#subscriptions"
          aria-expanded="false"
          aria-controls="subscriptions"
        >
          {t("payout.payout")}
        </button>
      </h2>
      <div
        id="subscriptions"
        className="accordion-collapse collapse d-lg-block subscriber-page"
        aria-labelledby="headingThree"
        data-bs-parent="#myTabContent"
      >
        <div className="accordion-body">
          <div className="box-container">
            <h2 className="setting-title"> {t("payout.payout")}</h2>
            <div className="payout-content">
              <div className="my-balance">
                <p>{t("payout.your coin balance")}:</p>
                <h4>
                  {postData?.wallet_balance ?? "0"}
                </h4>
              </div>
              <div className="amount-balance">
                <div className="dollor-img">
                  <img src={dollor} alt="coin" />
                </div>
                <input
                  type="number"
                  placeholder={
                    t("payout.enter amount to payout") ||
                    "Enter Amount to Payout"
                  }
                  value={amount}
                  onKeyDown={(e) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault()
                  }
                  onChange={(e) => {
                    setAmount(e.target.value);
                    setAmountError(false);
                  }}
                />

                <Button
                  className="btn-payout"
                  onClick={addPayout}
                  // onClick={addPayoutModal}
                  disabled={amountError}
                >
                  {t("payout.payout")}
                </Button>
              </div>

              <div className="">
                <p className="dolor-text">
                  {t("payout.you can only cashout coin once in 24 hour")}
                </p>
              </div>
              <div className="payout-history">
                <h4> {t("payout.payout history")}</h4>
                <ul>
                  {payoutList.length > 0 &&
                    payoutList.map((item: any) => {
                      return (
                        <li className="payout-item" key={item.amount}>
                          <div className="left-box">
                            <h6>{item?.amount}</h6>
                            <p>
                              {moment(item?.withdraw_datetime).format(
                                "D-MMM-YY"
                              )}
                            </p>
                          </div>
                          <Button
                            className={
                              item?.status === 0
                                ? "btn-pending"
                                : item?.status === 2
                                  ? "btn-danger"
                                  : "btn-success"
                            }
                          >
                            {item?.status === 0
                              ? t("payout.pending")
                              : item?.status === 2 ?
                                t("payout.reject")
                                : t("payout.success")}
                          </Button>
                        </li>
                      );
                    })}

                  {/* <li className="payout-item">
                   <div className="left-box">
                   <h6>$548</h6>
                   <p>28 July 2021</p>
                   </div>
                   <Button className="btn-pending">PENDING</Button>
                  </li>
                  <li className="payout-item">
                   <div className="left-box">
                   <h6>$548</h6>
                   <p>28 July 2021</p>
                   </div>
                   <Button className="btn-succedd ">PENDING</Button>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <PayoutModal
          show={showPayoutModal}
          setShow={setShowPayoutModel}
          packageId={packageId}
        /> */}
      </div>
    </>
  );
};
export default Payout;
