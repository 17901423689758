import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../redux";
import { exitLiveStreaming, getStoriesSuccess } from "../redux/action";
import IAgoraRTC from "agora-rtc-sdk-ng";
import { METHOD, apiCall } from "../service/baseApiCall";
import {
  CREATE_LIVE_STREMING_URL,
  CREATE_TOKEN_URL,
} from "../redux/ApiEndPoints";
import { setSubscriberToken } from "../redux/action";
import { Store } from "../redux/Actions";
import Loader from "./Loader";
import { toast } from "react-toastify";

const StartStreaming = (comProps: any) => {
  const [isJoined, setIsJoined] = useState<boolean>(false);
  const [isVideoReady, setIsVideoReady] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  var localTracks = {
    videoTrack: null as any,
    audioTrack: null as any,
  };
  const [remoteUsers, setRemoteUsers] = useState<any>({});

  const token = useSelector(
    (state: Store) => state.goLiveReducer.subscriberToken
  );
  const liveStreamJoined = useSelector(
    (state: Store) => state.goLiveReducer.liveStreamJoined
  );
  const storiesList: any = useSelector(
    (state: Store) => state.userDataReducer.stories
  );

  var options = {
    appid: "c9b1eca1d6694b92933d98bcea05140f",
    channel: "",
    uid: null as any,
    token: "",
    role: "audience",
    audienceLatency: 1,
  };
  // const [options, setOptions] = useState();
  const [client, setClient] = useState(null as any);

  const subscribe = async (user: any, mediaType: any) => {
    const uid = user.uid;
    // subscribe to a remote user
    await client.subscribe(user, mediaType);
    if (mediaType === "video") {
      const playerWrapper = document.createElement("div");
      playerWrapper.id = `player-wrapper-${uid}`;
      playerWrapper.classList.add("player-wrapper");

      const playerName = document.createElement("p");
      playerName.classList.add("player-name");
      playerName.textContent = `remoteUserd(${uid})`;

      const playerDiv = document.createElement("div");
      playerDiv.id = `player-${uid}`;
      playerDiv.style.width = "200px";
      playerDiv.style.height = "200px";
      playerDiv.classList.add("player");

      playerWrapper.appendChild(playerName);
      playerWrapper.appendChild(playerDiv);
      const remotePlayerlist = document.getElementById("remote-playerlist");
      if (remotePlayerlist) {
        remotePlayerlist.appendChild(playerWrapper);
      }
      user.videoTrack.play(`player-${uid}`, {
        fit: "contain",
      });
      setIsVideoReady(true);
    }
    if (mediaType === "audio") {
      user.audioTrack.play();
    }
  };

  const removeLiveStreamFromRedux = () => {
    const updatedStrories: any = storiesList;
    let updatedChannel: any = [];
    updatedChannel = updatedStrories.channels.filter(
      (channel: any) => channel.user.id != liveStreamJoined.user.id
    );
    updatedStrories.channels = updatedChannel;
    dispatch(getStoriesSuccess({ res: { data: updatedStrories } }));
  };

  const unsubscribe = async (user: any, mediaType: any) => {
    await client.unsubscribe(user, mediaType);
    try {
      if (mediaType === "video") {
        user.videoTrack.stop();
      }
      removeLiveStreamFromRedux();
      toast.error("Live stream ended");
      leaveChannel();
    } catch {
      console.log("");
    }

    try {
      if (mediaType === "audio") {
        user.audioTrack.stop();
      }
    } catch (error: any) {
      console.log(error);
    }
    const remotePlayerlist = document.getElementById("remote-playerlist");
    if (remotePlayerlist) {
      remotePlayerlist.innerText = "";
    }
  };

  const leaveChannel = () => {
    client.leave(() => {
      dispatch(exitLiveStreaming());
    });
    const remote = document.getElementById("remote-playerlist");
    if (remote) {
      remote.innerHTML = "";
    }
    comProps.setStreamCheck(false);
  };

  const handleUserPublished = (user: any, mediaType: any) => {
    const id = user.uid;
    setRemoteUsers((prevUsers: any) => ({ ...prevUsers, [id]: user }));

    subscribe(user, mediaType);
  };

  const handleUserUnpublished = async (user: any, mediaType: any) => {
    const id = user.uid;
    delete remoteUsers[id];
    setRemoteUsers((prevUsers: any) => ({ ...prevUsers }));

    await unsubscribe(user, mediaType);
  };

  const getAgoraToken = async () => {
    let data = { channelName: comProps.channelId };

    await apiCall(
      CREATE_TOKEN_URL,
      data,
      (res: any) => {
        dispatch(
          setSubscriberToken({
            rtmToken: res.data.rtmtoken,
            rtcToken: res.data.rtctoken,
          })
        );
      },
      (msg: string) => {
        console.error("Error in getting Agora token : %s", msg);
      },
      METHOD.POST
    );
  };

  const helper = async () => {
    if (options.role === "audience") {
      client.setClientRole(options.role, {
        level: options.audienceLatency,
      });
      // add event listener to play remote tracks when remote user publishs.
      client.on("user-published", handleUserPublished);
      client.on("user-unpublished", handleUserUnpublished);
    } else {
      client.setClientRole(options.role);
    }
    options.uid = await client.join(
      options.appid,
      options.channel,
      options.token,
      options.uid
    );
    if (options.role === "host") {
      // create local audio and video tracks
      if (!localTracks.audioTrack) {
        localTracks.audioTrack = await IAgoraRTC.createMicrophoneAudioTrack({
          encoderConfig: "music_standard",
        });
      }
      if (!localTracks.videoTrack) {
        localTracks.videoTrack = await IAgoraRTC.createCameraVideoTrack();

        await localTracks.videoTrack.setEncoderConfiguration({
          dimensions: {
            width: 1280,
            height: 720,
          },
          frameRate: 30,
          bitrate: 2000,
          orientationMode: "adaptative",
        });
      }
      await client.publish(Object.values(localTracks));
    }
  };

  const join = async () => {
    options.channel = comProps.channelId;
    options.token = token.rtcToken;
    options.uid = parseInt(comProps.id);
    await helper();
  };

  useEffect(() => {
    const createClient = async () => {
      const agoraClient = IAgoraRTC.createClient({
        mode: "live",
        codec: "vp8",
      });
      setClient(agoraClient);
      console.log(agoraClient, "agoraClientagoraClient");
      await getAgoraToken();
    };

    createClient();
  }, []);

  useEffect(() => {
    if (
      comProps.forceToLeaveStreaming ||
      (liveStreamJoined.stream_id === undefined && comProps.videocall)
    )
      leaveChannel();
  }, [comProps.forceToLeaveStreaming, liveStreamJoined.stream_id]);

  useEffect(() => {
    if (client && !isJoined && token.rtcToken && token.rtmToken) {
      join();
      setIsJoined(true);
    }
  }, [client, token]);
  return (
    <>
      <div className="position-relative h-100">
        <div
          id="remote-playerlist"
          className={`${
            comProps.screenToggle
              ? "remote-playerlist-contain"
              : "remote-playerlist-cover"
          }`}
        ></div>
        {isVideoReady ? (
          <div className="live-title-overlay">
            <div>
              <h3>{liveStreamJoined.title ?? ""}</h3>
              <p className="text-justify">
                {liveStreamJoined.description ?? ""}
              </p>
            </div>
            <button
              type="button"
              className="exit-btn full-screen"
              onClick={comProps.toggleFullScreen}
            >
              {/* <img alt='' src='assets/images/icons/logout.svg' className='me-1' /> */}
              {comProps.screenToggle ? "Exit Full Screen" : "Full Screen"}
            </button>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      {/* {!isJoined && <button onClick={join}>Join</button>} */}
      {/* <button onClick={leaveChannel}>Leave</button> */}
    </>
  );
};

export default StartStreaming;
