import { toast } from "react-toastify";
import { AMAZON_UPLOAD_URL } from "../../redux/ApiEndPoints";
import { apiCall, METHOD } from "../baseApiCall";

interface UploadResult {
  Key: string;
  Location: string;
}

export const uploadFileAmazonS3 = async (
  file: any,
  path?: any
): Promise<any> => {
  try {
    console.log(file, path);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("path", path);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return new Promise((resolve, reject) => {
      apiCall(
        AMAZON_UPLOAD_URL,
        formData,
        (res: any) => {
          console.log(res);

          const result: UploadResult = {
            Key: res.data.key,
            Location: res.data.location,
          };
          resolve(result);
        },
        (err: any) => {
          console.error(err);
          toast.error("Something went wrong! Please try again.");
          reject(undefined);
        },
        METHOD.POST,
        config
      );
    });
  } catch (err) {
    console.error("Error:", err);
    toast.error("Something went wrong! Please try again.");
    return undefined;
  }
};
